<template>
  <loader
    v-if="isLoading"
    message="Loading ...."
  />
  <div v-else>
    <v-container class="pa-0">
      <v-row class="pa-1 ma-0 my-3" style="background: #e0f4ff; border-radius: 8px;">
        <v-col class="d-flex flex-column py-0" cols="1">
          <s-text weight="medium" color="gray" size="sm">
            Type
          </s-text>
        </v-col>
        <v-col class="d-flex flex-column py-0" cols="3">
          <s-text weight="medium" color="gray" size="sm">
            User
          </s-text>
        </v-col>
        <v-col class="d-flex flex-column py-0" cols="2">
          <s-text weight="medium" color="gray" size="sm">
            Amount
          </s-text>
        </v-col>
        <v-col class="d-flex flex-column py-0" cols="2">
          <s-text weight="medium" color="gray" size="sm">
            Status
          </s-text>
        </v-col>
        <v-col class="d-flex flex-column py-0" cols="3">
          <s-text weight="medium" color="gray" size="sm">
            Date Sent
          </s-text>
        </v-col>
      </v-row>
      <v-row
        v-for="txn in txns"
        :key="txn.id"
        class="pa-0 ma-0 mb-3 app-card app-card-content"
        @click="openTransactionDetails(txn)"
      >
        <v-col class="d-flex flex-column" cols="1">
          <div>
            {{ txn.type }}
          </div>
        </v-col>
        <v-col class="d-flex flex-column" cols="3">
          <s-link
            weight="medium"
            color="grayTextAlt"
            :to="{ name: 'appDetails', params: { id: txn.user_id } }"
          >
            {{ txn.user_id }}
          </s-link>
        </v-col>
        <v-col class="d-flex flex-column" cols="2">
          <div>
            {{ txn.currency }} {{ getCurrencyFormatted(txn.amount) }}
          </div>
        </v-col>
        <v-col class="d-flex flex-column" cols="2">
          <div>
            {{ txn.status }}
          </div>
        </v-col>
        <v-col class="d-flex flex-column" cols="3">
          <div>
            {{ txn.created_at ? getHumanReadableDateShort(txn.created_at) : "-" }}
          </div>
        </v-col>
        <v-col class="action_group d-flex align-center flex-row-reverse" cols="1">
          <s-icon name="arrow-circle-right" class="mr-2" />
        </v-col>
      </v-row>
    </v-container>
    <s-dialog
      v-model="showLogDetailsModal"
      persistent
      isModal
      title="Message Details"
      :close-default="false"
      @close="showLogDetailsModal = false;"
    >
      <view-txn-details
        v-if="showLogDetailsModal"
        :txn="txn"
        @close="showLogDetailsModal = false"
      />
    </s-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ViewTxnDetails from '@/components/transaction/ViewTxnDetails'
import Loader from '@/components/cards/Loader'
import { getHumanReadableDateShort } from '@/utils/date'
export default {
  name: 'Transactions',
  components: {
    loader: Loader,
    'view-txn-details': ViewTxnDetails,
  },
  props: {
    txnId: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      txns: 'txns/transactions',
    }),
  },
  data () {
    return {
      isLoading: false,
      txn: null,
      showLogDetailsModal: false,
    }
  },
  methods: {
    getHumanReadableDateShort,
    getCurrencyFormatted (unit) {
      return (unit / 100).toFixed(2)
    },
    getLogs () {
      this.isLoading = true
      this.$store
        .dispatch('txns/getTransactions')
        .finally(() => {
          this.isLoading = false
        })
    },
    openTransactionDetails (txn) {
      this.showLogDetailsModal = true
      this.txn = txn
    },
  },
  watch: {
    txns: {
      handler () {
        if (this.txns) return
        this.getLogs()
      },
      immediate: true,
    },
  },
}
</script>
